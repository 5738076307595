// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archanes-js": () => import("./../src/pages/archanes.js" /* webpackChunkName: "component---src-pages-archanes-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-story-js": () => import("./../src/pages/Story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-wines-js": () => import("./../src/pages/Wines.js" /* webpackChunkName: "component---src-pages-wines-js" */)
}

